import React, { createContext, useEffect, useState } from 'react'
import { Alert, Modal, Snackbar } from '@mui/material'
import { onForegroundPushListener } from '../utils/firebase'
import { MessagePayload } from 'firebase/messaging'
import { Loading, Logo, Text } from '../components'

declare global {
  interface Window {
    registration: any
  }
}

type MessageType = 'success' | 'info' | 'warning' | 'error'

export const NotificationContext = createContext<{
  showSnack: (message: string, type?: MessageType) => void
  showModal: (title: string, body?: React.ReactNode, image?: string) => void
  showLoading: (loading: boolean) => void
}>({
  showSnack: () => undefined,
  showModal: () => undefined,
  showLoading: () => undefined,
})

const SnackProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [snack, setSnack] = useState<{
    message: string
    type: MessageType
  }>()

  const [modal, setModal] = useState<{
    title: string
    body?: React.ReactNode
    image?: string
  }>()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (onForegroundPushListener) {
      const handleForegroundPush = (payload: MessagePayload) => {
        console.log('handleForegroundPush:', payload)
        setModal({
          title: payload.notification?.title || '',
          body: <>{payload.notification?.body}</>,
          image: payload.notification?.image,
        })

        const notificationTitle = payload.notification?.title || ''
        const notificationOptions = {
          body: payload.notification?.body,
          icon: payload.notification?.icon,
          image: payload.notification?.image,
        }

        if (!('Notification' in window)) {
          console.log('This browser does not support system notifications')
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === 'granted') {
          // If it's okay let's create a notification
          var notification = new Notification(
            notificationTitle,
            notificationOptions
          )
          notification.onclick = function (event) {
            event.preventDefault() // prevent the browser from focusing the Notification's tab
            notification.close()
          }
        }
      }

      onForegroundPushListener(handleForegroundPush)

      return () => {
        onForegroundPushListener(handleForegroundPush)
      }
    }
  }, [onForegroundPushListener])

  return (
    <NotificationContext.Provider
      value={{
        showSnack: (message: string, type: MessageType = 'info') =>
          setSnack({ message, type }),
        showModal: (title: string, body?: React.ReactNode) =>
          setModal({ title, body }),
        showLoading: (loading: boolean) => setLoading(loading),
      }}
    >
      {loading && <Loading />}
      <Snackbar
        open={!!snack}
        autoHideDuration={3000}
        onClose={() => setSnack(undefined)}
      >
        {snack && <Alert severity={snack.type}>{snack.message}</Alert>}
      </Snackbar>
      <Modal open={!!modal} onClose={() => setModal(undefined)}>
        <div
          className="bg-white mt-5 p-4 rounded"
          style={{ maxWidth: 800, margin: 'auto' }}
        >
          <div className="d-flex">
            <Logo size={48} />
            <Text className="mx-1" type="h6" style={{ fontWeight: 700 }}>
              {modal?.title}
            </Text>
          </div>
          {modal?.body && (
            <div
              className="mt-2"
              style={{ maxHeight: 600, overflowY: 'scroll' }}
            >
              {modal?.body}
            </div>
          )}
          {modal?.image && (
            <img width="100%" alt="Bot front image" src={modal?.image} />
          )}
        </div>
      </Modal>
      {children}
    </NotificationContext.Provider>
  )
}

export default SnackProvider
