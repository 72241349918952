import React, { createContext, useContext, useState, useMemo } from 'react'
import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { NotificationContext } from '../../../providers/notification'
import { CheckBox, TextInput } from '../../../components'
import appConfig, { Labels } from '../../../utils/config'
import { UserContext } from '../../../providers/user'
import DeleteIcon from '@mui/icons-material/Delete'
import { ITodoTask } from '../../../models/user'

interface Context {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
})

const DragHandle: React.FC = () => {
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <div
      ref={ref}
      {...listeners}
      {...attributes}
      style={{ paddingLeft: 15, paddingRight: 10, touchAction: 'none' }}
    >
      <DragIndicatorIcon color="primary" style={{ width: 15 }} />
    </div>
  )
}

export const ToDoTask: React.FC<{
  index: number
  task: ITodoTask
}> = ({ index, task }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: task.taskId })
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  )
  const { todoTasks, setTodoTasks, updateTodoTasks } = useContext(UserContext)
  const { showSnack } = useContext(NotificationContext)

  const [editMode, setEditMode] = useState(false)

  const onTaskDoneChange = () => {
    if (todoTasks && !task.done) {
      const updatedTasks = todoTasks.map((t) =>
        t.taskId === task.taskId
          ? { ...t, done: true, updatedAt: Date.now() }
          : t
      )
      setTodoTasks(updatedTasks)
      updateTodoTasks(updatedTasks)
      showSnack(task.title, 'success')
    }
  }

  const onTaskTitleChange = (result: string) => {
    if (todoTasks && !task.done) {
      const updatedTasks = todoTasks.map((t) =>
        t.taskId === task.taskId ? { ...t, title: result } : t
      )
      setTodoTasks(updatedTasks)
    }
  }

  const onTaskTitleFocus = () => {
    setEditMode(true)
  }

  const onTaskTitleBlur = () => {
    if (todoTasks && !task.done) {
      setTimeout(() => setEditMode(false), 100)
      updateTodoTasks(todoTasks)
    }
  }

  const onDeleteTask = () => {
    if (todoTasks) {
      const updatedTasks = todoTasks.filter((t) => t.taskId !== task.taskId)
      setTodoTasks(updatedTasks)
      updateTodoTasks(updatedTasks)
    }
  }

  return (
    <SortableItemContext.Provider value={context}>
      <div
        ref={setNodeRef}
        style={{ opacity: isDragging ? 0.4 : undefined, transform: CSS.Translate.toString(transform), transition }}
        className={`d-flex align-items-center ${
          index === 0 && 'shadow-sm my-2 border rounded rounded-3 '
        }`}
      >
        <DragHandle />
        <div className="d-flex w-100 align-items-center">
          <CheckBox
            checked={task.done}
            className="text-primary"
            onChange={() => onTaskDoneChange()}
          />
          <TextInput
            value={task.title}
            className="w-100"
            variant={`${index === 0 && 'fw-bolder'}`}
            onBlur={() => onTaskTitleBlur()}
            onFocus={() => onTaskTitleFocus()}
            onChange={(result) => onTaskTitleChange(result)}
          />
          {editMode && (
            <div className="mx-2" onClick={() => onDeleteTask()}>
              <DeleteIcon color="secondary" className="fs-5" />
            </div>
          )}
        </div>
      </div>
    </SortableItemContext.Provider>
  )
}

export default ToDoTask

const LABELS: Labels = {
  en: {},
  pt: {},
}

const labels = LABELS[appConfig.language]
