import React, { useContext, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { publishEvent } from './utils/firebase'
import { Loading, NavBar } from './components'
import { AuthContext } from './providers/auth'
import AppsProvider from './providers/apps'
import UserProvider from './providers/user'
import BotProvider from './providers/bot'
import Document from './views/document'
import Landing from './views/landing'
import Profile from './views/profile'
import Places from './views/places'
import Feed from './views/feed'
import ToDo from './views/todo'
import Logs from './views/logs'
import Bots from './views/bots'
import Bot from './views/bot'

const Router: React.FC = () => {
  const location = useLocation()
  const { isLoading, user } = useContext(AuthContext)

  const publicRoutes = () => (
    <>
      <Route path={LINKS.install} element={<Document name="install" />} />
      <Route path={LINKS.privacy} element={<Document name="privacy" />} />
      <Route path={LINKS.terms} element={<Document name="terms" />} />
    </>
  )

  useEffect(() => {
    publishEvent('page_view', { path: location.pathname })
  }, [location])

  return (
    <>
      <NavBar />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="h-100 overflow-scroll p-2">
          <div style={{ maxWidth: 600, margin: '0 auto' }}>
            {!user ? (
              <Routes>
                <Route path={LINKS.home} element={<Landing />} />
                {publicRoutes()}
              </Routes>
            ) : (
              <UserProvider>
                <AppsProvider>
                  <BotProvider>
                    <Routes>
                      {publicRoutes()}
                      <Route path={LINKS.home} element={<ToDo />} />
                      <Route path={LINKS.todo} element={<ToDo />} />
                      <Route path={LINKS.feed} element={<Feed />} />
                      <Route path={LINKS.bots} element={<Bots />} />
                      <Route path={LINKS.place} element={<Places />} />
                      <Route path={LINKS.profile} element={<Profile />} />
                      <Route path={LINKS.bot(':botId')} element={<Bot />} />
                      <Route path={LINKS.logs(':botId')} element={<Logs />} />
                      <Route path="*" element={<Profile />} />
                    </Routes>
                  </BotProvider>
                </AppsProvider>
              </UserProvider>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Router

export const LINKS = {
  home: '/',
  bots: '/bots',
  todo: '/todo',
  feed: '/feed',
  place: '/place',
  terms: '/terms',
  profile: '/profile',
  privacy: '/privacy',
  install: '/install',
  bot: (botId: string) => `/bots/${botId}`,
  logs: (botId: string) => `/bots/${botId}/logs`,
}
