import React, { useContext, useEffect, useState } from 'react'
import {
  IServiceApp,
  IVariable,
  MethodName,
  ServiceName,
  ServiceType,
} from '../../../../models/service'
import { NotificationContext } from '../../../../providers/notification'
import { ITask } from '../../../../models/bot'
import appConfig, { Labels } from '../../../../utils/config'
import PushNotificationService from './pushNotification'
import { AppsContext } from '../../../../providers/apps'
import { IAppConnection } from '../../../../models/app'
import { BotContext } from '../../../../providers/bot'
import { OptionsInput } from '../../../../components'
import NewConnection from './newConnection'
import WebhookService from './webhook'

const TaskService: React.FC<{
  taskIndex: number
}> = ({ taskIndex }) => {
  const { services } = useContext(AppsContext)
  const { bot, getBot, updateBotTask } = useContext(BotContext)
  const { showSnack } = useContext(NotificationContext)

  const [task, setTask] = useState<ITask>()

  const onSelectService = (appService: IServiceApp) => {
    if (bot && task) {
      if (appService) {
        const updatedTask = {
          ...task,
          app: appService.app,
          service: appService.service,
          sampleResult: undefined,
        }
        if (appService.service.config.customFields) {
          updatedTask.inputData = []
        }

        updateBotTask(bot.botId, taskIndex, updatedTask)
      } else {
        const updatedTask = {
          taskId: task.taskId,
          inputData: [],
        }

        updateBotTask
      }
    }
  }

  const onSelectConnection = (appConnection: IAppConnection) => {
    if (bot && task) {
      task.connectionId = appConnection ? appConnection.connectionId : undefined
      updateBotTask(bot.botId, taskIndex, task)
    }
  }

  const updateBotInputField = (fieldName: string, inputField: IVariable) => {
    if (bot && task) {
      const inputDataIndex = task.inputData.findIndex(
        (x) => x.name === fieldName
      )

      if (inputDataIndex >= 0) {
        task.inputData[inputDataIndex] = inputField
      } else {
        task.inputData.push(inputField)
      }

      updateBotTask(bot.botId, taskIndex, task)
    }
  }

  const pollBot = () => {
    if (bot && task) {
      let attempts = 0

      const checkNewConnection = () => {
        getBot(bot.botId).then(() => {
          attempts++
          if (attempts >= 20) return
          else if (!bot.tasks[taskIndex].connectionId) {
            setTimeout(checkNewConnection, 1000, taskIndex)
          } else {
            showSnack(labels.newConnectionSuccess, 'success')
          }
        })
      }

      checkNewConnection()
    }
  }

  useEffect(() => {
    if (bot) {
      setTask(bot.tasks[taskIndex])
    }
  }, [bot])

  return (
    <>
      {bot && task && (
        <div className="d-block w-100">
          {/***** Service information *****/}
          <OptionsInput
            label={labels.chooseService}
            value={task.service?.label || ''}
            onChange={onSelectService}
            groupLabelPath={'app.name'}
            optionLabelPath={'service.label'}
            options={
              taskIndex === 0
                ? services.filter((x) => x.service.type === ServiceType.trigger)
                : services.filter((x) => x.service.type !== ServiceType.trigger)
            }
          />

          {/***** Account information *****/}
          {task.app?.config.auth?.url && (
            <NewConnection
              botId={bot.botId}
              taskIndex={taskIndex}
              appId={task.app?.appId}
              connectionId={task.connectionId}
              appAuthUrl={task.app?.config?.authorizeUrl}
              appLoginUrl={task.app?.config?.loginUrl}
              onSelectConnection={onSelectConnection}
              onNewConnectionAttempt={pollBot}
            />
          )}

          {/***** Webhook specific display *****/}
          {task.service?.name === ServiceName.webhook && (
            <WebhookService triggerUrl={bot.triggerUrl} />
          )}

          {/***** Push notification specific display *****/}
          {task.service?.config?.methodName === MethodName.sendNotification && (
            <PushNotificationService
              inputData={task.inputData}
              updateBotInputField={updateBotInputField}
            />
          )}
        </div>
      )}
    </>
  )
}

export default TaskService

const LABELS: Labels = {
  en: {
    chooseService: 'Choose a task',
    newConnectionSuccess: 'Connection created sucessfully :)',
  },
  pt: {
    chooseService: 'Escolha uma tarefa',
    newConnectionSuccess: 'Conecção criada com sucesso :)',
  },
}

const labels = LABELS[appConfig.language]
