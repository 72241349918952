import React, { useRef, useEffect } from 'react'
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import { DataType } from '../models/service'
import { ComponentProps } from '.'

const Highlight: React.FC<
  { data: DataType; language?: string } & ComponentProps
> = ({ data, language = 'json', className, style }) => {
  const codeNode = useRef(null)

  useEffect(() => {
    if (codeNode.current) {
      hljs.highlightElement(codeNode.current)
    }
  }, [codeNode])

  return (
    <pre className={className} style={{ ...style, margin: 0 }}>
      <code ref={codeNode} className={`rounded language-${language}`}>
        {JSON.stringify(data, null, 2)}
      </code>
    </pre>
  )
}

export default Highlight
