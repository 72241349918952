import React from 'react'
import { ITask, ITaskCondition } from '../../../../models/bot'
import appConfig, { Labels } from '../../../../utils/config'
import { newEmptyCondition } from './filterConditions'
import { CheckBox } from '../../../../components'

const ExtraOptions: React.FC<{
  task: ITask
  showReturnDataOption: boolean
  onTaskFieldChange: (fieldName: string, value: any) => void
}> = ({ task, showReturnDataOption, onTaskFieldChange }) => {
  const onConditionsOptionChange = (checked: boolean) => {
    const initialConditions: ITaskCondition[][] = [[newEmptyCondition]]

    onTaskFieldChange('conditions', !checked ? undefined : initialConditions)
  }

  const onReturnDataOptionChange = () => {
    onTaskFieldChange('returnData', !task.returnData)
  }

  return (
    <>
      <CheckBox
        label={labels.conditionsOption}
        checked={!!task.conditions}
        onChange={onConditionsOptionChange}
      />
      {showReturnDataOption && (
        <CheckBox
          label={labels.returnDataOption}
          checked={!!task.returnData}
          onChange={onReturnDataOptionChange}
        />
      )}
    </>
  )
}

export default ExtraOptions

const LABELS: Labels = {
  en: {
    conditionsOption: 'Only executes if',
    returnDataOption: 'Retrieve data on trigger response',
  },
  pt: {
    conditionsOption: 'Execute somente se',
    returnDataOption: 'Retornar dados como resposta da requisição',
  },
}

const labels = LABELS[appConfig.language]
