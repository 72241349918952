import React, { useContext, useEffect, useState } from 'react'
import { ServiceName, ServiceType } from '../../../../models/service'
import { BotContext } from '../../../../providers/bot'
import FilterConditions from './filterConditions'
import { ITask } from '../../../../models/bot'
import ExtraOptions from './extraOptions'

const TaskOptions: React.FC<{
  taskIndex: number
}> = ({ taskIndex }) => {
  const { bot, getBotInputs, updateBotTask } = useContext(BotContext)

  const [task, setTask] = useState<ITask>()

  const updateTaskOptions = (fieldName: string, value: any) => {
    if (bot) {
      const updatedTask = { ...task, [fieldName]: value } as ITask
      updateBotTask(bot.botId, taskIndex, updatedTask, false)
    }
  }

  useEffect(() => {
    if (bot) {
      setTask(bot.tasks[taskIndex])
    }
  }, [bot])

  return (
    <>
      {bot && task && (
        <>
          {/***** Filter conditions *****/}
          {task.service?.type !== ServiceType.trigger && task.conditions && (
            <FilterConditions
              task={task}
              taskIndex={taskIndex}
              inputs={getBotInputs(bot.tasks)}
              onTaskFieldChange={updateTaskOptions}
            />
          )}

          {/***** Extra options *****/}
          <ExtraOptions
            task={task}
            onTaskFieldChange={updateTaskOptions}
            showReturnDataOption={
              bot.tasks[0].service?.name === ServiceName.webhook
            }
          />
        </>
      )}
    </>
  )
}

export default TaskOptions
