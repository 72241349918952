import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { BotContext } from '../../providers/bot'
import { Loading } from '../../components'
import Skeleton from './components/skeleton'
import TopBar from './components/topBar'
import Task from './components/task'

export const BotComponent: React.FC = () => {
  const { botId } = useParams()
  const { bot, getBot } = useContext(BotContext)

  useEffect(() => {
    if (botId && !bot) {
      getBot(botId)
    }
  }, [botId])

  return (
    <div className="mt-2">
      {!bot ? (
        <Skeleton />
      ) : (
        <>
          <TopBar
            name={bot.name}
            image={bot.image}
            isActive={bot.active}
            description={bot.description}
          />

          {bot.tasks.map((_, taskIndex) => (
            <Task key={taskIndex} taskIndex={taskIndex} />
          ))}
        </>
      )}
    </div>
  )
}

export default withAuthenticationRequired(BotComponent, {
  onRedirecting: () => <Loading />,
})
