import React from 'react'
import { ComponentProps } from '.'

const Logo: React.FC<{ size?: number } & ComponentProps> = ({
  size = 48,
  className,
  style,
}) => {
  return (
    <div className={`d-flex ${className}`} style={style}>
      <img
        width={size}
        alt="Baita logo"
        style={{ margin: -size / 6 }}
        src={require('../assets/logo.png')}
      />
    </div>
  )
}

export default Logo
