import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppBar, Toolbar } from '@mui/material'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MenuIcon from '@mui/icons-material/Menu'
import FaceIcon from '@mui/icons-material/Face'
import { AuthContext } from '../providers/auth'
import { Button, ComponentProps, Logo, Text } from '.'
import appConfig, { Labels } from '../utils/config'
import { LINKS } from '../router'
import Menu from './menu'

const NavBar: React.FC<ComponentProps> = ({ className, style }) => {
  const navigate = useNavigate()

  const { isLoading, isAdmin, user, login, logout } = useContext(AuthContext)

  return (
    <div className={className} style={{ ...style, zIndex: 1000 }}>
      <AppBar position="static">
        <Toolbar className="justify-content-between">
          <div
            className="d-flex align-items-center"
            onClick={() => navigate(!user ? LINKS.home : LINKS.profile)}
          >
            <Button iconButton style={{ marginLeft: -10 }} icon={<Logo />} />
            <Text className="fw-bold text-contrast">Baita</Text>
          </div>
          {!isLoading && (
            <>
              {!user ? (
                <Button
                  type="text"
                  color="inherit"
                  className="text-contrast"
                  onClick={login}
                >
                  {labels.login}
                </Button>
              ) : (
                <Menu
                  links={[
                    {
                      label: user?.name as string,
                      onClick: () => navigate(LINKS.profile),
                      icon: !user?.picture ? (
                        <FaceIcon />
                      ) : (
                        <img
                          width="24"
                          src={user.picture}
                          alt="User profile picture"
                          className="rounded-circle"
                        />
                      ),
                    },
                    {
                      label: labels.feed,
                      onClick: () => navigate(LINKS.feed),
                      icon: <NewspaperIcon color="secondary" />,
                    },
                    {
                      label: labels.toDo,
                      onClick: () => navigate(LINKS.todo),
                      icon: <ListAltIcon color="secondary" />,
                    },
                    {
                      label: labels.bots,
                      onClick: () => navigate(LINKS.bots),
                      icon: <SmartToyOutlinedIcon color="secondary" />,
                    },
                    {
                      label: labels.place,
                      onClick: () => navigate(LINKS.place),
                      icon: <ExploreOutlinedIcon color="secondary" />,
                    },
                    {
                      label: labels.logout,
                      onClick: logout,
                      icon: <ExitToAppIcon color="secondary" />,
                    },
                  ]}
                >
                  <MenuIcon />
                </Menu>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar

const LABELS: Labels = {
  en: {
    bots: 'Bots',
    feed: 'Feed',
    toDo: 'To Do',
    place: 'Place',
    profile: 'Profile',
    logout: 'Logout',
    login: 'Log in',
  },
  pt: {
    bots: 'Bots',
    feed: 'Feed',
    toDo: 'To Do',
    place: 'Lugar',
    profile: 'Perfil',
    logout: 'Sair',
    login: 'Entrar',
  },
}

const labels = LABELS[appConfig.language]
