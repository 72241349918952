import React, { useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { NotificationContext } from '../../../../providers/notification'
import appConfig, { Labels } from '../../../../utils/config'
import { Button } from '../../../../components'

const WebhookService: React.FC<{ triggerUrl: string }> = ({ triggerUrl }) => {
  const { showSnack } = useContext(NotificationContext)

  return (
    <div
      className="d-flex justify-content-center my-3 align-middle"
      style={{ alignItems: 'center' }}
    >
      <div>
        {labels.triggerUrl}
        {triggerUrl}
      </div>
      <div className="mx-3">
        <CopyToClipboard
          text={triggerUrl}
          onCopy={() => showSnack(labels.urlCopiedToClipboard, 'success')}
        >
          <Button iconButton icon={<FileCopyIcon />} />
        </CopyToClipboard>
      </div>
    </div>
  )
}

export default WebhookService

const LABELS: Labels = {
  en: {
    triggerUrl: 'URL: ',
    urlCopiedToClipboard: 'URL copied to clipboard',
  },
  pt: {
    triggerUrl: 'URL: ',
    urlCopiedToClipboard: 'URL copiada para área de transferência',
  },
}

const labels = LABELS[appConfig.language]
