import React from 'react'
import { Chip } from '@mui/material'
import { TaskExecutionStatus } from '../models/bot'
import { ComponentProps } from '.'

const StatusChip: React.FC<
  {
    status: TaskExecutionStatus
  } & ComponentProps
> = ({ status, style, className }) => {
  return (
    <div className={className} style={style}>
      <Chip
        label={status}
        className={`
          ${
            status === TaskExecutionStatus.success
              ? 'border border-success'
              : ''
          }
          text-${
            status === TaskExecutionStatus.fail
              ? 'white'
              : status === TaskExecutionStatus.filtered
              ? 'white'
              : ''
          }
          bg-${
            status === TaskExecutionStatus.success
              ? 'white'
              : status === TaskExecutionStatus.fail
              ? 'danger'
              : 'secondary'
          }`}
      />
    </div>
  )
}

export default StatusChip
