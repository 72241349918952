import Axios from 'axios'

const ConnectionRequest = () => {
  const createConnection = (
    url: string,
    auth: {
      username: string
      password: string
    }
  ) => {
    return Axios.request({ method: 'post', url, auth })
  }

  return {
    createConnection,
  }
}

export default ConnectionRequest
