import React, { useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Highlight, StatusChip, Text } from '../../../components'
import appConfig, { Labels } from '../../../utils/config'
import { AuthContext } from '../../../providers/auth'
import { IBotLog } from '../../../models/bot'

const Log: React.FC<{ botLog: IBotLog }> = ({ botLog }) => {
  const { isAdmin } = useContext(AuthContext)

  return (
    <div className="mb-5" key={botLog.timestamp}>
      <Accordion>
        <AccordionSummary disabled>
          <Text>
            {`${botLog.usage} ${labels.header} ${
              botLog.usage > 1 ? 's' : ''
            }, ${new Date(botLog.timestamp)}`}
          </Text>
        </AccordionSummary>
      </Accordion>
      {botLog.logs.map((log) => (
        <Accordion key={`${log.name}-${log.timestamp}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StatusChip status={log.status} />
            <Text className="mx-3 align-self-center">{log.name}</Text>
          </AccordionSummary>
          <AccordionDetails className="d-block">
            {isAdmin && log.inputData && (
              <>
                <Text className="mb-1">{labels.inputData}</Text>
                {log.inputData ? <Highlight data={log.inputData} /> : ''}
              </>
            )}
            {log.outputData && (
              <>
                <Text className="mb-1">{labels.outputData}</Text>
                <Highlight data={log.outputData} />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default Log

const LABELS: Labels = {
  en: {
    header: 'task',
    inputData: 'Input',
    outputData: 'Output',
  },
  pt: {
    header: 'tarefa',
    inputData: 'Entrada',
    outputData: 'Saída',
  },
}

const labels = LABELS[appConfig.language]
