import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
  isSupported,
  Unsubscribe,
} from 'firebase/messaging'
import { getAnalytics, logEvent } from 'firebase/analytics'
import appConfig from './config'

const app = initializeApp({
  apiKey: 'AIzaSyDoIre-UjQst3DAw-fGi-LWL0Z08VVLriI',
  authDomain: 'baita-373213.firebaseapp.com',
  projectId: 'baita-373213',
  storageBucket: 'baita-373213.appspot.com',
  messagingSenderId: '106617044495',
  appId: '1:106617044495:web:bb732658a8bbb349c7ed7a',
  measurementId: 'G-RVXM9VHZ19',
})

isSupported().then((support) => {
  if (support) {
    const messaging = getMessaging(app)

    getPushToken = () =>
      getToken(messaging, {
        vapidKey:
          'BEtO-XBbnNK6GNeT8LVi9-ty7uNCqK4shp9QI4I1K-kE6snCWyKfapZAt2YGhG2A0wIIlQkliEdzEmJlTfeauUk',
      })

    onForegroundPushListener = (callback: (payload: MessagePayload) => void) =>
      onMessage(messaging, (payload: MessagePayload) => {
        publishEvent('push_received', payload)
        callback(payload)
      })
  }
})

export let getPushToken: () => Promise<string>

export let onForegroundPushListener: (
  callback: (payload: MessagePayload) => void
) => Unsubscribe

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app)

export const publishEvent = (
  eventName: string,
  params?: { [key: string]: any }
) => appConfig.isProduction && logEvent(analytics, eventName, params)
