import React from 'react'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import appConfig, { Labels } from '../../../utils/config'
import { Button, Text } from '../../../components'

const TopBar: React.FC<{
  botName?: string
  onRefreshClick: () => void
}> = ({ botName, onRefreshClick }) => {
  return (
    <div className="d-flex align-items-center justify-content-between mx-3">
      <div className="d-flex text-primary align-items-center">
        <SmartToyOutlinedIcon color="secondary" />
        <Text className="fw-bold fs-3 mx-1">
          {botName === undefined ? '...' : botName || labels.noNameBot}
        </Text>
      </div>
      <Button
        iconButton
        icon={<RefreshIcon />}
        tooltip={labels.refreshTooltip}
        onClick={onRefreshClick}
      />
    </div>
  )
}

export default TopBar

const LABELS: Labels = {
  en: {
    botTitle: 'logs of',
    refreshTooltip: 'Refresh',
    noNameBot: 'no name bot',
  },
  pt: {
    botTitle: 'logs de',
    refreshTooltip: 'Atualizar',
    noNameBot: 'Bot sem name',
  },
}

const labels = LABELS[appConfig.language]
