import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import * as serviceWorker from './serviceWorker'
import authConfig from './auth0.json'
import App from './app'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Auth0Provider
    domain={authConfig.domain}
    clientId={authConfig.clientId}
    audience={authConfig.audience}
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    scope="offline_access"
  >
    <App />
  </Auth0Provider>
)

serviceWorker.register()
