import React from 'react'
import { DragEndEvent, DndContext } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { ITodoTask } from '../../../models/user'
import ToDoTask from './todoTask'

export const ToDoList: React.FC<{
  tasks: ITodoTask[]
  onChange: (tasks: ITodoTask[]) => void
}> = ({ tasks, onChange }) => {
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = tasks.findIndex(({ taskId }) => taskId === active.id)
      const overIndex = tasks.findIndex(({ taskId }) => taskId === over.id)

      onChange(arrayMove(tasks, activeIndex, overIndex))
    }
  }

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext items={tasks.map((task) => task.taskId)}>
        {tasks.map((task, i) => (
          <ToDoTask key={task.taskId} index={i} task={task} />
        ))}
      </SortableContext>
    </DndContext>
  )
}

export default ToDoList
