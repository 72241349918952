import React from 'react'
import { Autocomplete, Chip, TextField } from '@mui/material'
import appConfig, { Labels } from '../utils/config'
import { ComponentProps } from '.'

export const OptionsInput: React.FC<
  {
    value: string
    onChange: (value: any) => void
    onBlur?: () => void
    label?: string
    placeholder?: string
    size?: 'small' | 'medium'
    options?: any[]
    chip?: boolean
    optionLabelPath?: string | string[]
    groupLabelPath?: string | string[]
  } & ComponentProps
> = ({
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  size = 'medium',
  options,
  chip = false,
  optionLabelPath,
  groupLabelPath,
  className,
  style,
}) => {
  const getPropertyByPath = (path: string | string[], obj: any): string => {
    const properties = Array.isArray(path) ? path : path.split('.')
    return properties.reduce((prev, curr) => prev[curr], obj)
  }

  return (
    <div className={`w-100 mt-1 ${className}`} style={style}>
      {options && (
        <Autocomplete
          openOnFocus
          autoHighlight
          blurOnSelect
          options={options}
          inputValue={value}
          noOptionsText={labels.noOptions}
          filterOptions={(options) => options}
          onBlur={onBlur}
          onChange={(_, value) => onChange(value)}
          groupBy={
            groupLabelPath
              ? (option) => getPropertyByPath(groupLabelPath, option)
              : undefined
          }
          getOptionLabel={(option) =>
            getPropertyByPath(`${optionLabelPath}`, option)
          }
          isOptionEqualToValue={() => true}
          renderOption={
            chip
              ? (props, option) => (
                  <li {...props}>
                    <Chip
                      variant="outlined"
                      label={getPropertyByPath(`${optionLabelPath}`, option)}
                    />
                  </li>
                )
              : undefined
          }
          onKeyDown={(event) => event.preventDefault()}
          renderInput={(params) => (
            <TextField
              {...params}
              size={size}
              label={label}
              variant="outlined"
              placeholder={placeholder}
              onKeyDown={(event) => event.preventDefault()}
            />
          )}
        />
      )}
    </div>
  )
}

export default OptionsInput

const LABELS: Labels = {
  en: {
    noOptions: 'No options available :(',
  },
  pt: {
    noOptions: 'Nehuma opção disponível :(',
  },
}

const labels = LABELS[appConfig.language]
